import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import styled, { createGlobalStyle } from 'styled-components'
import Map from './img/stend4.jpg'

import { createEvent, createStore } from 'effector'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
  nav {
    background-color: #e1eaf2;
  }

  footer{
    color: #bacbda;
  }
`

const LayoutCatalog = styled.div`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  background-color: rgb(0, 36, 71);

  .catalog_wrapper {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
  canvas {
    display: block;
    cursor: grab;
    margin-top: 50px;
  }
`

function getMousePos(canvas, evt, matrix, invMatrix) {
  const rect = canvas.getBoundingClientRect(),
    scaleX = canvas.width / rect.width,
    scaleY = canvas.height / rect.height

  let mX = (evt.clientX - rect.left) * scaleX
  let mY = (evt.clientY - rect.top) * scaleY

  const x =
    mX * invMatrix[0] + mY * invMatrix[2] + invMatrix[4] - matrix[4] / matrix[0]
  const y =
    mX * invMatrix[1] + mY * invMatrix[3] + invMatrix[5] - matrix[5] / matrix[3]
  return {
    x,
    y,
  }
}

const isShow = createStore(false)
const setIsShow = createEvent()

const isClicked = createStore(false)
const setIsClicked = createEvent()

const activeTag = createStore(false)
const setActiveTag = createEvent()

isShow.on(setIsShow, (state, newState) => newState)
isClicked.on(setIsClicked, (state, newState) => newState)
activeTag.on(setActiveTag, (state, newState) => newState)

const tags = [
  {
    x: 2594,
    y: 655,
    id: 19,
    link:
      '/catalog/Могила%20И.В.%20Гекало,%20комиссара%20огневой%20бригады%2051-й%20дивизии,%20участвовавшей%20в%20штурме%20Перекопа%20в%201920%20году%20(ОКН%20№462)/',
    prompt: 'Могила И.В. Гекало',
  },
  {
    x: 2892,
    y: 603,
    id: 7,
    link:
      '/catalog/Участок%20воинских%20захоронений%20советских%20воинов%20-%20двадцать%20шесть%20одиночных%20могил%20и%20одна%20братская%20(ОКН%20№450)/',
    prompt: 'Двадцать шесть одиночных могил и одна братская',
  },
  {
    x: 2970,
    y: 940,
    id: 4,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№447)/',
    prompt: 'Братская могила советских воинов №447',
  },
  {
    x: 3015,
    y: 716,
    id: 22,
    link:
      '/catalog/Могила%20Героя%20Советского%20Союза%20капитана%20И.Н.%20Лихого%20(ОКН%20№465)/',
    prompt: 'Могила И.Н. Лихого',
  },
  {
    x: 3085,
    y: 690,
    id: 9,
    link:
      '/catalog/Братская%20могила%20членов%20подпольной%20группы%20«Сокол»%20(ОКН%20№452)/',
    prompt: 'Братская могила членов подпольной группы «Сокол»',
  },
  {
    x: 3105,
    y: 736,
    id: 18,
    link:
      '/catalog/Могила%20старшины%202-й%20статьи,%20санинструктора%20Е.%20Ф.%20Дерюгиной%20(ОКН%20№461)/',
    prompt: 'Могила Е.Ф. Дерюгиной',
  },
  {
    x: 3122,
    y: 790,
    id: 8,
    link: '/catalog/Могила%20партизана%20Ф.Н.%20Мельникова%20(ОКН%20№451)/',
    prompt: 'Могила партизана Ф.Н. Мельникова',
  },
  {
    x: 3172,
    y: 780,
    id: 16,
    link: '/catalog/Могила%20подпольщицы%20З.М.%20Рухадзе%20(ОКН%20№459)/',
    prompt: 'Могила подпольщицы З.М. Рухадзе',
  },
  {
    x: 3172,
    y: 835,
    id: 15,
    link: '/catalog/Могила%20подпольщика%20Ю.В.%20Рожкова%20(ОКН%20№458)/',
    prompt: 'Могила подпольщика Ю.В. Рожкова',
  },
  {
    x: 3224,
    y: 835,
    id: 12,
    link: '/catalog/Могила%20подпольщика%20В.В.%20Дацуна%20(ОКН%20№455)/',
    prompt: 'Могила подпольщика В.В. Дацуна',
  },
  {
    x: 3211,
    y: 885,
    id: 14,
    link: '/catalog/Могила%20подпольщика%20И.И.%20Носенко%20(ОКН%20№457)/',
    prompt: 'Могила подпольщика И.И. Носенко',
  },
  {
    x: 3105,
    y: 1015,
    id: 5,
    link:
      '/catalog/Братская%20могила%20советских%20воинов%20и%20партизанки%20Л.И.%20Вовченко%20(ОКН%20№448)/',
    prompt: 'Могила Л.И. Вовченко',
  },
  {
    x: 3150,
    y: 1040,
    id: 17,
    link: '/catalog/Могила%20подпольщика%20В.Б.%20Сергеева%20(ОКН%20№460)/',
    prompt: 'Могила подпольщика В.Б. Сергеева',
  },
  {
    x: 3268,
    y: 905,
    id: 10,
    link: '/catalog/Могила%20партизана%20Ю.Х.%20Амберьяди%20(ОКН%20№453)/',
    prompt: 'Могила партизана Ю.Х. Амберьяди',
  },
  {
    x: 3295,
    y: 947,
    id: 13,
    link: '/catalog/Могила%20подпольщика%20Л.Г.%20Тарабукина%20(ОКН%20№456)/',
    prompt: 'Могила подпольщика Л.Г. Тарабукина',
  },
  {
    x: 3298,
    y: 1000,
    id: 6,
    link:
      '/catalog/Участок%20воинских%20захоронений%20советских%20воинов%20-%20пять%20одиночных%20могил%20и%20одна%20братская%20могила%20(ОКН%20№449)/',
    prompt: 'Пять одиночных могил и одна братская могила',
  },
  {
    x: 3332,
    y: 1036,
    id: 11,
    link: '/catalog/Могила%20подпольщика%20Г.А.%20Тайшина%20(ОКН%20№454)/',
    prompt: 'Могила подпольщика Г.А. Тайшина',
  },
  {
    x: 2724,
    y: 1297,
    id: 20,
    link: '/catalog/Могила%20подполковника%20М.С.%20Модина%20(ОКН%20№463)/',
    prompt: 'Могила подполковника М.С. Модина',
  },
  {
    x: 3050,
    y: 1920,
    id: 2,
    link:
      '/catalog/Могила%20советских%20воинов%20И.%20В.%20Сидорова%20и%20майора%20А.%20Е.%20Мищенко%20(ОКН%20№445)/',
    prompt: 'Могила И.В. Сидорова и А.Е. Мищенко',
  },
  {
    x: 1814,
    y: 766,
    id: 52,
    link: '/catalog/Могила%20Чирахова%20Ованеса%20Карапетовича/',
    prompt: 'Могила Чирахова Ованеса Карапетовича',
  },
  {
    x: 1953,
    y: 773,
    id: 53,
    link: '/catalog/Могила%20Чираховой%20Христины%20Христофоровны/',
    prompt: 'Могила Чираховой Христины Христофоровны',
  },
  {
    x: 3108,
    y: 2575,
    id: 35,
    link: '/catalog/Могила%20Коловрат-Червинской%20Марии%20Евстафьевны/',
    prompt: 'Могила Коловрат-Червинской Марии Евстафьевны',
  },
  {
    x: 3477,
    y: 2620,
    id: 32,
    link: '/catalog/Могила%20Измаильской%20(Неручевой)%20Таисии%20Васильевны/',
    prompt: 'Могила Измаильской (Неручевой) Таисии Васильевны',
  },
  {
    x: 3535,
    y: 2395,
    id: 50,
    link: '/catalog/Могила%20фон%20Витте%20Августа%20Карловича/',
    prompt: 'Могила фон Витте Августа Карловича',
  },
  {
    x: 3450,
    y: 2832,
    id: 48,
    link: '/catalog/Могила%20Тиль%20Иоанна/',
    prompt: 'Могила Тиль Иоанна',
  },
  {
    x: 3488,
    y: 2888,
    id: 51,
    link: '/catalog/Могила%20Фризона%20Александра/',
    prompt: 'Могила Фризона Александра',
  },
  {
    x: 3528,
    y: 2942,
    id: 54,
    link: '/catalog/Могила%20Янковского%20Викентия/',
    prompt: 'Могила Янковского Викентия',
  },
  {
    x: 3605,
    y: 3065,
    id: 31,
    link: '/catalog/Могила%20Ивницкого%20Якова%20Клементьевича/',
    prompt: 'Могила Ивницкого Якова Клементьевича',
  },
  {
    x: 3835,
    y: 3365,
    id: 26,
    link: '/catalog/Могила%20Белокуренко%20Петра%20Ивановича/',
    prompt: 'Могила Белокуренко Петра Ивановича',
  },
  {
    x: 4132,
    y: 3270,
    id: 40,
    link: '/catalog/Могила%20Оливер%20Екатерины%20Карловны/',
    prompt: 'Могила Оливер Екатерины Карловны',
  },
  {
    x: 3998,
    y: 3668,
    id: 1,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№444)/',
    prompt: 'Братская могила советских воинов №444',
  },
  {
    x: 4365,
    y: 3425,
    id: 33,
    link:
      '/catalog/Могила%20Казариновой%20(Каретниковой)%20Валентины%20Павловны/',
    prompt: 'Могила Казариновой Валентины Павловны',
  },
  {
    x: 4379,
    y: 3493,
    id: 47,
    link: '/catalog/Могила%20Спасского%20Николая%20Сергеевича/',
    prompt: 'Могила Спасского Николая Сергеевича',
  },
  {
    x: 3900,
    y: 4176,
    id: 34,
    link: '/catalog/Могила%20Калошина%20Алексея%20Андреевича/',
    prompt: 'Могила Калошина Алексея Андреевича',
  },
  {
    x: 4276,
    y: 4050,
    id: 3,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№446)/',
    prompt: 'Братская могила советских воинов №446',
  },
  {
    x: 4564,
    y: 3965,
    id: 36,
    link: '/catalog/Могила%20Крутик-Гортубанского%20Ивана%20Григорьевича/',
    prompt: 'Могила Крутик-Гортубанского Ивана Григорьевича',
  },
  {
    x: 4564,
    y: 4080,
    id: 43,
    link:
      '/catalog/Могила%20Поповых%20Аполлинария%20Арсентьевича%20и%20Анны%20Андреевны/',
    prompt: 'Могила Поповых Аполлинария и Анны',
  },
  {
    x: 4564,
    y: 4393,
    id: 41,
    link: '/catalog/Могила%20Перлштейна%20Алексея%20Акимовича/',
    prompt: 'Могила Перлштейна Алексея Акимовича',
  },
  {
    x: 4467,
    y: 4242,
    id: 30,
    link: '/catalog/Могила%20Загарской%20(Перовской)%20Марии%20Львовны/',
    prompt: 'Могила Загарской (Перовской) Марии Львовны',
  },
  {
    x: 4554,
    y: 4311,
    id: 21,
    link:
      '/catalog/Могила%20академика%20батальной%20живописи%20Н.С.%20Самокиша%20(ОКН%20№464)/',
    prompt: 'Могила академика Н.С. Самокиша',
  },
  {
    x: 4736,
    y: 4311,
    id: 29,
    link: '/catalog/Могила%20Гуковой%20Алевтины%20Ивановны/',
    prompt: 'Могила Гуковой Алевтины Ивановны',
  },
  {
    x: 4658,
    y: 4191,
    id: 27,
    link: '/catalog/Могила%20Бетлинга%20Эдуарда%20Николаевича/',
    prompt: 'Могила Бетлинга Эдуарда Николаевича',
  },
  {
    x: 4795,
    y: 4016,
    id: 49,
    link: '/catalog/Могила%20Умова%20Владимира%20Алексеевича/',
    prompt: 'Могила Умова Владимира Алексеевича',
  },
  {
    x: 4891,
    y: 4292,
    id: 44,
    link: '/catalog/Могила%20Сачавца%20Аполлона%20Григорьевича/',
    prompt: 'Могила Сачавца Аполлона Григорьевича',
  },
  {
    x: 4402,
    y: 4671,
    id: 45,
    link: '/catalog/Могила%20Синодского%20Владимира%20Васильевича/',
    prompt: 'Могила Синодского Владимира Васильевича',
  },
  {
    x: 4214,
    y: 4500,
    id: 37,
    link: '/catalog/Могила%20Маркевич%20Анны%20Николаевны/',
    prompt: 'Могила Маркевич Анны Николаевны',
  },
  {
    x: 4132,
    y: 4552,
    id: 42,
    link:
      '/catalog/Могила%20Плешковых%20Николая%20Владимировича%20и%20Марии%20Николаевны/',
    prompt: 'Могила Плешковых Николая и Марии',
  },
  {
    x: 4221,
    y: 4552,
    id: 38,
    link:
      '/catalog/Могила%20Маркевича%20Георгия%20Арсентьевича%20и%20Владимира%20Арсентьевича/',
    prompt: 'Могила братьев Георгия и Владимира Маркевич',
  },
  {
    x: 4265,
    y: 4574,
    id: 46,
    link: '/catalog/Могила%20Сонцова%20%20Адриана%20%20Александровича/',
    prompt: 'Могила Сонцова Адриана Александровича',
  },
  {
    x: 7585,
    y: 1154,
    id: 24,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№506)/',
    prompt: 'Братская могила советских воинов №506',
  },
  {
    x: 7550,
    y: 2235,
    id: 25,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№507)/',
    prompt: 'Братская могила советских воинов №507',
  },
  {
    x: 7652,
    y: 2130,
    id: 23,
    link: '/catalog/Братская%20могила%20советских%20воинов%20(ОКН%20№466)/',
    prompt: 'Братская могила советских воинов №466',
  },
  {
    x: 8313,
    y: 2690,
    id: 28,
    link:
      '/catalog/Могила%20Брунсов%20Михаила%20Федоровича,%20Миши%20и%20Феди/',
    prompt: 'Могила Брунсов Михаила Федоровича, Миши и Феди',
  },
  {
    x: 8465,
    y: 2970,
    id: 39,
    link: '/catalog/Могила%20Мочалина%20Николая%20Захаровича/',
    prompt: 'Могила Мочалина Николая Захаровича',
  },
]

function showPropmt(dx, dy, canvas) {
  const belongDomain = ({ x, y }) => {
    return dx >= x && dx <= x + 55 && dy >= y && dy <= y + 55
  }
  if (tags.some(belongDomain)) {
    canvas.style.cursor = 'pointer'
    setActiveTag(tags.find(belongDomain))
    setIsShow(true)
  } else {
    canvas.style.cursor = 'grab'
    setActiveTag(undefined)
    setIsShow(false)
  }
}

function mouseMove(event, mouse, canvas, displayTransform) {
  if (canvas && displayTransform) {
    const { matrix, invMatrix } = displayTransform
    const { x, y } = getMousePos(canvas, event, matrix, invMatrix)

    showPropmt(x, y, canvas)
  }

  mouse.x = event.offsetX
  mouse.y = event.offsetY
  if (mouse.x === undefined) {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }
  mouse.alt = event.altKey
  mouse.shift = event.shiftKey
  mouse.ctrl = event.ctrlKey
  if (event.type === 'mousedown') {
    event.preventDefault()
    mouse.buttonRaw |= mouse.buttons[event.which - 1]
  } else if (event.type === 'mouseup') {
    mouse.buttonRaw &= mouse.buttons[event.which + 2]
  } else if (event.type === 'mouseout') {
    mouse.buttonRaw = 0
    mouse.over = false
  } else if (event.type === 'mouseover') {
    mouse.over = true
  } else if (event.type === 'mousewheel') {
    event.preventDefault()
    mouse.w = event.wheelDelta
  } else if (event.type === 'DOMMouseScroll') {
    // FF you pedantic doffus
    mouse.w = -event.detail
  }
}

function setupMouseToCanvas(canvas, mouse, displayTransform) {
  canvas.addEventListener('mousemove', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('mousedown', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('mouseup', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('mouseout', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('mouseover', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('mousewheel', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('DOMMouseScroll', (e) =>
    mouseMove(e, mouse, canvas, displayTransform)
  )
  canvas.addEventListener('click', (e) => {
    if (e) {
      setIsClicked(true)
      if (isShow.getState()) {
        const { link } = activeTag.getState()
        window.location = link
        setIsClicked(false)
      }
    }
  })

  canvas.addEventListener('contextmenu', (e) => e.preventDefault(), false)
}

function displayUpdate(mouse) {
  // smooth all movement out. drag and accel control how this moves
  // acceleration
  this.dx += (this.x - this.cx) * this.accel
  this.dy += (this.y - this.cy) * this.accel
  this.dox += (this.ox - this.cox) * this.accel
  this.doy += (this.oy - this.coy) * this.accel
  this.dscale += (this.scale - this.cscale) * this.accel
  this.drotate += (this.rotate - this.crotate) * this.accel
  // drag
  this.dx *= this.drag
  this.dy *= this.drag
  this.dox *= this.drag
  this.doy *= this.drag
  this.dscale *= this.drag
  this.drotate *= this.drag
  // set the chase values. Chase chases the requiered values
  this.cx += this.dx
  this.cy += this.dy
  this.cox += this.dox
  this.coy += this.doy
  this.cscale += this.dscale
  this.crotate += this.drotate

  // create the display matrix
  this.matrix[0] = Math.cos(this.crotate) * this.cscale
  this.matrix[1] = Math.sin(this.crotate) * this.cscale
  this.matrix[2] = -this.matrix[1]
  this.matrix[3] = this.matrix[0]

  // set the coords relative to the origin
  this.matrix[4] =
    -(this.cx * this.matrix[0] + this.cy * this.matrix[2]) + this.cox
  this.matrix[5] =
    -(this.cx * this.matrix[1] + this.cy * this.matrix[3]) + this.coy

  // create invers matrix
  var det = this.matrix[0] * this.matrix[3] - this.matrix[1] * this.matrix[2]
  this.invMatrix[0] = this.matrix[3] / det
  this.invMatrix[1] = -this.matrix[1] / det
  this.invMatrix[2] = -this.matrix[2] / det
  this.invMatrix[3] = this.matrix[0] / det

  // check for mouse. Do controls and get real position of mouse.
  if (mouse !== undefined) {
    // if there is a mouse get the real cavas coordinates of the mouse
    if (mouse.oldX !== undefined && (mouse.buttonRaw & 1) === 1) {
      let mdx = mouse.x - mouse.oldX // get the mouse movement
      let mdy = mouse.y - mouse.oldY

      let mrx = mdx * this.invMatrix[0] + mdy * this.invMatrix[2]
      let mry = mdx * this.invMatrix[1] + mdy * this.invMatrix[3]
      this.x -= mrx * 2
      this.y -= mry * 2
    }

    if (mouse.w !== undefined && mouse.w !== 0) {
      this.ox = mouse.x
      this.oy = mouse.y
      this.x = this.mouseX
      this.y = this.mouseY
      if (mouse.w > 0) {
        // zoom in
        this.scale *= 1.05
        mouse.w -= 20
        if (mouse.w < 0) {
          mouse.w = 0
        }
      }
      if (mouse.w < 0) {
        // zoom out
        this.scale *= 1 / 1.05
        mouse.w += 20
        if (mouse.w > 0) {
          mouse.w = 0
        }
      }
    }
    // get the real mouse position
    let screenX = mouse.x - this.cox
    let screenY = mouse.y - this.coy
    this.mouseX =
      this.cx + (screenX * this.invMatrix[0] + screenY * this.invMatrix[2])
    this.mouseY =
      this.cy + (screenX * this.invMatrix[1] + screenY * this.invMatrix[3])
    mouse.rx = this.mouseX // add the coordinates to the mouse. r is for real
    mouse.ry = this.mouseY
    // save old mouse position
    mouse.oldX = mouse.x
    mouse.oldY = mouse.y
  }
}

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke == 'undefined') {
    stroke = true
  }
  if (typeof radius === 'undefined') {
    radius = 5
  }
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
  if (stroke) {
    ctx.stroke()
  }
  if (fill) {
    ctx.fill()
  }
}

function SmallMapCanvas() {
  const canvasRef = React.useRef(null)

  React.useEffect(() => {
    const canvas = canvasRef.current

    canvas.width = 9231 / 2
    canvas.height = 5035 / 2

    const ctx = canvas.getContext('2d')

    const mouse = {
      x: 0,
      y: 0,
      w: 0,
      alt: false,
      shift: false,
      ctrl: false,
      buttonLastRaw: 0, // user modified value
      buttonRaw: 0,
      over: false,
      buttons: [1, 2, 4, 6, 5, 3], // masks for setting and clearing button raw bits;
    }

    const displayTransform = {
      x: 0,
      y: 0,
      ox: 0,
      oy: 0,
      scale: 0.5,
      rotate: 0,
      cx: 0, // chase values Hold the actual display
      cy: 0,
      cox: 0,
      coy: 0,
      cscale: 0.5,
      crotate: 0,
      dx: 0, // deltat values
      dy: 0,
      dox: 0,
      doy: 0,
      dscale: 0.5,
      drotate: 0,
      drag: 0.5, // drag for movements
      accel: 0.1, // acceleration
      matrix: [0, 0, 0, 0, 0, 0], // main matrix
      invMatrix: [0, 0, 0, 0, 0, 0], // invers matrix;
      mouseX: 0,
      mouseY: 0,
      ctx: ctx,
      setTransform: function () {
        let m = this.matrix
        let i = 0
        this.ctx.setTransform(m[i++], m[i++], m[i++], m[i++], m[i++], m[i++])
      },
      setHome: function () {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0)
      },
    }

    setupMouseToCanvas(canvas, mouse, displayTransform)

    const img = new Image()
    img.src = Map

    function update() {
      displayUpdate.call(displayTransform, mouse)
      displayTransform.setHome()

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      if (img.complete) {
        displayTransform.setTransform()
        ctx.drawImage(img, 0, 0)

        // for (let { x, y, id } of tags) {
        //   ctx.lineWidth = 1;
        //   ctx.strokeStyle = 'cyan'
        //   ctx.strokeRect(x, y, 55, 55)
        // }

        if (isShow.getState()) {
          const { prompt, x, y } = activeTag.getState()
          ctx.lineWidth = 4
          ctx.strokeStyle = '#080808'
          ctx.fillStyle = '#f0f3a6'
          roundRect(
            ctx,
            x - 10,
            y - 50,
            ctx.measureText(prompt).width + 20,
            50,
            10,
            true
          )
          ctx.font = 'normal normal bold 24px Arial'
          ctx.fillStyle = '#080808'
          ctx.fillText(prompt, x, y - 15)
        }
      } else {
        displayTransform.setTransform()
        ctx.font = '400px verdana'
        ctx.fillText('Загрузка изображения...', 9231 / 4, 5035 / 2)
        ctx.font = '24px verdana'
      }
      if (mouse.buttonRaw === 4) {
        displayTransform.x = 0
        displayTransform.y = 0
        displayTransform.scale = 0.5
        displayTransform.rotate = 0
        displayTransform.ox = 0
        displayTransform.oy = 0
      }
      requestAnimationFrame(update)
    }
    update()
  }, [])

  return (
    <canvas
      id="bigCanvas"
      ref={canvasRef}
      style={{
        width: '100%',
      }}
    />
  )
}

const Help = styled.div`
  background: #e1eaf2;
  padding: 0 30px;
  padding-bottom: 30px;

  img {
    max-width: 100px;
  }
`

function TrueInteractivePlan(props) {
  // pan https://stackoverflow.com/questions/33925012/how-to-pan-the-canvas
  // scale https://jsfiddle.net/ubaqn9j6/3/

  return (
    <Layout location="/war">
      <GlobalStyle />
      <LayoutCatalog>
        <Helmet title="Интерактивный план захоронений" />
        <div className="catalog_wrapper">
          <SmallMapCanvas />
          <Help>
            <div>
              <h1>Перемещение и переход</h1>
              <p>
                Чтобы посмотреть другой участок карты, нажмите и, удерживая
                левую кнопку мыши, передвиньте карту в нужном направлении.
              </p>
              <p>
                Для того, чтобы перейти к объекту — наведите мышь на номер
                объекта на карте и нажмите левую кнопку мыши.
              </p>
            </div>
            <div>
              <h1>Изменение масштаба карты</h1>
              <p>
                Покрутите колесо мыши на себя, чтобы уменьшить масштаб, или от
                себя — чтобы увеличить.
              </p>
              <p>
                Чтобы вернуть масштаб по умолчанию — нажмите правую кнопку мыши.
              </p>
            </div>
            <div>
              <h1>Примечание</h1>
              <p>
                Управление картой возможно только при помощи мыши.
              </p>
            </div>
          </Help>
        </div>
      </LayoutCatalog>
    </Layout>
  )
}

export default TrueInteractivePlan